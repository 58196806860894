import React from 'react';
import styled from '@emotion/styled';
import {useConnect, useAccount, Connector} from 'wagmi';
import {Dialog, DialogTitle, DialogContent} from '@mui/material';
import {WalletConnectIcon, CoinbaseIcon, MetamaskIcon} from './icons';
import {isMobile} from '../../utils/detect-broswer';
import {useToast} from '../../utils/hooks';

const Button = styled.button`
	all: unset;
	width: 240px;
	display: flex;
	font-size: 18px;
	font-weight: 500;
	margin-top: 1rem;
	padding: 20px 14px;
	border-radius: 8px;
	align-items: center;
	justify-content: space-between;
	background-color: rgba(150, 180, 180, 0.05);
	transition: all 0.6s cubic-bezier(0, 0, 0.4, 1);
	cursor: pointer;
	:hover {
		text-decoration: none;
		background-color: rgba(150, 180, 180, 0.1);
	}
`;

interface ConnectDialogProps {
	open: boolean;
	onClose: VoidFunction;
}

const icons = [<MetamaskIcon />, <CoinbaseIcon />, <WalletConnectIcon />];

export default function ConnectDialog({
	open = false,
	onClose,
}: ConnectDialogProps) {
	const toast = useToast();
	const {isConnected} = useAccount();
	const {connect, connectors, error, isLoading, pendingConnector} =
		useConnect();

	const walletConnector = connectors.find(
		(v) => v.name.toLowerCase() === 'walletconnect',
	);

	const isMetaMaskInjected =
		typeof window !== 'undefined' &&
		typeof window.ethereum !== 'undefined' &&
		window.ethereum.isMetaMask;

	const shouldUseWalletConnect = isMobile() || !isMetaMaskInjected;

	const handleConnect = (connector: Connector, useWalletConnect: Boolean) => {
		if (useWalletConnect && walletConnector) {
			connect({connector: walletConnector});
		} else {
			connect({connector});
		}
	};

	React.useEffect(() => {
		if (error) {
			console.error(error);
			toast('error', '连接钱包失败');
		}
	}, [error]);

	React.useEffect(() => {
		if (isConnected) {
			onClose();
		}
	}, [isConnected]);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>连接钱包</DialogTitle>
			<DialogContent>
				{connectors.map((v, i) => (
					<Button
						key={v.id}
						disabled={isLoading}
						onClick={() =>
							handleConnect(v, i === 0 && shouldUseWalletConnect)
						}>
						{v.name}
						{icons[i]}
					</Button>
				))}
			</DialogContent>
		</Dialog>
	);
}

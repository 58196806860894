import React from 'react';
import {useAccount} from 'wagmi';
import {watchNetwork} from '@wagmi/core';
import styled from '@emotion/styled';
import {useRecoilState} from 'recoil';
import {
	Alert,
	Button,
	Snackbar,
	createTheme,
	ThemeProvider,
} from '@mui/material';
import {toastState, connectDialogState} from '../common/atoms';
import {useToast} from '../utils/hooks';
import ConnectDialog from './Dialogs/ConnectDialog';

const Root = styled.div`
	width: 100%;
	margin: 0 auto;
	padding: 32px 0;
	max-width: 960px;
`;

type LayoutProps = {
	children: React.ReactNode;
};

export default function Layout({children}: LayoutProps) {
	const [toast, setToast] = useRecoilState(toastState);
	const [dialog, setDialog] = useRecoilState(connectDialogState);
	const {isConnected, connector} = useAccount();
	const openToast = useToast();

	const theme = createTheme({
		components: {
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 12,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 8,
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						boxShadow: '0 2px 5px rgba(10, 20, 20, 0.2)',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: 12,
					},
				},
			},
		},
		palette: {
			primary: {
				main: '#009ba1',
			},
			// mode: darkMode.value ? 'dark' : 'light',
		},
	});

	const handleClose = () => setToast((prev) => ({...prev, open: false}));

	React.useEffect(() => {
		if (!isConnected) {
			setDialog(true);
			openToast('info', '请连接钱包以获得完整体验');
		}
	}, [isConnected]);

	React.useEffect(() => {
		if (connector) {
			connector.getChainId().then((chainId) => {
				if (chainId !== 5) {
					openToast('warning', '本合约仅支持 Goerli 链');
				}
			});
		}
	}, [connector]);

	React.useEffect(
		() =>
			watchNetwork(({chain}) => {
				if (chain && chain.id !== 5) {
					openToast('warning', '本合约仅支持 Goerli 链');
				}
			}),
		[],
	);

	return (
		<ThemeProvider theme={theme}>
			<Root>{children}</Root>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={toast.open}
				autoHideDuration={3000}
				onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity={toast.type}
					action={
						toast.hash ? (
							<Button
								size="small"
								color="inherit"
								target="_blank"
								href={`https://goerli.etherscan.io/tx/${toast.hash}`}>
								查看交易
							</Button>
						) : null
					}>
					{toast.text}
				</Alert>
			</Snackbar>
			<ConnectDialog open={dialog} onClose={() => setDialog(false)} />
		</ThemeProvider>
	);
}

import {AlertColor} from '@mui/material';
import {atom} from 'recoil';

interface toast {
	open: boolean;
	type: AlertColor;
	text: string;
	hash?: string;
}

export const claimState = atom({
	key: 'claimState',
	default: 0,
});

export const toastState = atom<toast>({
	key: 'toastState',
	default: {
		open: false,
		type: 'success',
		text: '',
		hash: '',
	},
});

export const pageState = atom({
	key: 'pageState',
	default: 0,
});

export const menuState = atom({
	key: 'menuState',
	default: false,
});

export const connectDialogState = atom({
	key: 'connectDialogState',
	default: false,
});

import {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {AlertColor} from '@mui/material';
import {graphql, useStaticQuery} from 'gatsby';
import {useContract as wagmiUseContract, useSigner, useProvider} from 'wagmi';
import {toastState} from '../common/atoms';
import {contractAddress, contractAbi} from '../utils/constants';

// 全站可用的通知钩子
export function useToast() {
	const [_toast, setToast] = useRecoilState(toastState);

	return function toast(type: AlertColor, text: string, hash = '') {
		setToast({open: true, type, text, hash});
	};
}

/**
 * 当页面加载完成时由 false 变为 true
 * @returns {boolean} hasMounted
 */
export function useHasMounted(): boolean {
	const [hasMounted, setHasMounted] = useState(false);
	useEffect(() => {
		setHasMounted(true);
	}, []);
	return hasMounted;
}

/**
 * 获取 siteMetadata
 * @returns {object} siteMetadata
 */
export function useSiteMetadata(): Queries.SiteMetadata {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					siteUrl
					description
					social {
						twitter
						github
					}
				}
			}
		}
	`);
	return data.site.siteMetadata;
}

export function useContract() {
	const {data: signer} = useSigner();
	const provider = useProvider();

	return wagmiUseContract({
		abi: contractAbi,
		address: contractAddress,
		signerOrProvider: signer || provider,
	});
}

import React from 'react';
import {RecoilRoot} from 'recoil';
import {publicProvider} from 'wagmi/providers/public';
import {WagmiConfig, configureChains, createClient, goerli} from 'wagmi';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet';

const {chains, provider, webSocketProvider} = configureChains(
	[goerli],
	[publicProvider()],
);

const client = createClient({
	autoConnect: true,
	provider,
	webSocketProvider,
	connectors: [
		new MetaMaskConnector({chains}),
		new CoinbaseWalletConnector({
			chains,
			options: {
				appName: '链坛',
			},
		}),
		new WalletConnectConnector({
			chains,
			options: {
				qrcode: true,
			},
		}),
	],
});

export default function GatsbyRoot({element}: {element: React.ReactElement}) {
	return (
		<RecoilRoot>
			<WagmiConfig client={client}>{element}</WagmiConfig>
		</RecoilRoot>
	);
}
